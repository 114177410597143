import React from "react";
import Helmet from "react-helmet";
import Navbar from "../components/Navbar";
import "./all.sass";
import useSiteMetadata from "./SiteMetadata";

const TemplateWrapper = ({ children }) => {
  const { title, description } = useSiteMetadata();
  return (
    <div>
      <Helmet>
        <html lang="fr" />
        <title>{title}</title>
        <meta name="title" content={title} />
        <meta name="description" content={description} />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/img/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          href="/img/favicon-32x32.png"
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href="/img/favicon-16x16.png"
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href="/img/safari-pinned-tab.svg"
          color="#ff4400"
        />
        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content="https://ovdesign.fr/" />
        <meta
          property="og:image"
          content="https://ucarecdn.com/509f6962-ca07-49a8-a8a9-8fc0d0774fa8/"
        />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://ovdesign.fr/" />
        <meta property="twitter:title" content={title} />
        <meta property="twitter:description" content={description} />
        <meta
          property="twitter:image"
          content="https://ucarecdn.com/509f6962-ca07-49a8-a8a9-8fc0d0774fa8/"
        />

        <link
          href="https://fonts.googleapis.com/css?family=Raleway|Roboto&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <div>{children}</div>
    </div>
  );
};

export default TemplateWrapper;
