import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Link, graphql, StaticQuery } from "gatsby";
import ImageGallery from "react-image-gallery";

import "../../node_modules/react-image-gallery/styles/css/image-gallery.css";

export const Project = ({ project }) => {
  const [open, setOpen] = useState(false);
  const galleryRef = useRef(React.createRef());
  let covers = [];
  if (project.frontmatter && project.frontmatter.covers) {
    covers = project.frontmatter.covers;
  }
  let images = [];
  if (project.frontmatter && project.frontmatter.images) {
    images = project.frontmatter.images;
  }

  useEffect(() => {
    if (!open) {
      return;
    }
    if (!galleryRef.current) {
      return;
    }
    galleryRef.current.fullScreen();
  }, [open, galleryRef.current]);

  return (
    <article className="project" key={project.id}>
      <div>
        <div className="project-title">{project.frontmatter.title}</div>
        <span> &bull; </span>
        <span className="project-date">{project.frontmatter.date}</span>
      </div>
      <p>{project.frontmatter.description}</p>
      <div className="gallery">
        <div className="previews" onClick={() => setOpen(true)}>
          {covers.map((image, index) => (
            <div>
              <img key={image} src={`${image}-/scale_crop/320x240/smart`} />
            </div>
          ))}
        </div>
        {!!images.length && (
          <div className="more-pictures" onClick={() => setOpen(true)}>
            Voir plus
          </div>
        )}
        {!!images.length && open && (
          <ImageGallery
            items={images.map(image => ({
              original: `${image}-/preview/1024x1024/`,
              thumbnail: `${image}-/scale_crop/160x120/smart`,
            }))}
            ref={galleryRef}
            onScreenChange={open => setOpen(open)}
            useBrowserFullscreen={false}
            showPlayButton={false}
            onScreenChange={setOpen}
          />
        )}
      </div>
    </article>
  );
};

export class BlogRoll extends React.Component {
  render() {
    const { data } = this.props;
    const { edges: projects } = data.allMarkdownRemark;

    return (
      <div className="projects">
        {projects &&
          projects.map(({ node: project }) => <Project project={project} />)}
      </div>
    );
  }
}

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" }, homepage: { eq: true } } }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
                description
                covers
                images
                templateKey
                date(formatString: "D MMMM YYYY", locale: "fr")
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRoll data={data} count={count} />}
  />
);
