import React from "react";
import { Link, graphql, StaticQuery } from "gatsby";

import Layout from "../../components/Layout";
import { BlogRoll } from "../../components/BlogRoll";

class BlogIndexPage extends React.Component {
  render() {
    const { data, count } = this.props;
    const {
      title,
      subtitle,
      image,
    } = this.props.data.markdownRemark.frontmatter;
    return (
      <Layout>
        <header
          style={{
            backgroundImage: `linear-gradient(
          rgba(0, 0, 0, 0.3),
          rgba(0, 0, 0, 0.3)
        ), url(${image})`,
            backgroundPosition: `top left`,
            backgroundAttachment: `fixed`,
            height: "40vh",
          }}
          className="hero"
        >
          <div>
            <div className="title-container">
              <div className="title">
                <a href="/">{title}</a>
              </div>
            </div>
            <div className="subtitle">{subtitle}</div>
          </div>
        </header>
        <div className="container">
          <div className="content">
            <section style={{ marginTop: "3em" }}>
              <Link className="btn xs see-more" to="/">
                &lt; Retour
              </Link>
              <BlogRoll data={data} count={count} />
              <Link className="btn xs see-more" to="/">
                &lt; Retour
              </Link>
            </section>
          </div>
        </div>
      </Layout>
    );
  }
}

export default () => (
  <StaticQuery
    query={graphql`
      query FullBlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
                description
                covers
                images
                templateKey
                date(formatString: "D MMMM YYYY", locale: "fr")
              }
            }
          }
        }
        markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
          frontmatter {
            title
            subtitle
            image
            heading
            tel
            mail
            avatar
          }
        }
      }
    `}
    render={(data, count) => <BlogIndexPage data={data} count={count} />}
  />
);
